
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { Promised } from 'vue-promised';
import { AxiosError } from 'axios';
import FullScreenForm from '@/components/FullScreenForm.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { RouteNames } from '@/constants';
import CreateScheduledReportForm from '@/components/CreateScheduledReportForm.vue';
import { ScheduledReport } from '@/types/om27.types';
import { RpaOpsModule } from '@/store/om27.module';
import { openErrorSnackbar, openSuccessSnackbar } from '@/utils/components';

@Component({
  components: {
    FullScreenForm,
    CreateScheduledReportForm,
    Grid,
    GridCell,
    Promised,
  },
})
export default class ScheduledReportingCreate extends Vue {
  closeRoute: RawLocation = {
    name: RouteNames.RpaOpsScheduledReporting,
  };

  reportName!: string;
  formLoading = false;

  get mode(): string {
    if (this.$route.name === RouteNames.RpaOpsScheduledReportingEdit) {
      return 'edit';
    }
    return 'create';
  }

  goToScheduledReportsList(): void {
    this.$router
      .replace(this.closeRoute)
      .catch(() => void 0);
  }

  promise: Promise<ScheduledReport | null> | null = null;
  created() {
    const { reportName } = this.$route.params;
    if (reportName) {
      this.promise = RpaOpsModule.getScheduledReportByName(reportName);
    } else {
      this.promise = Promise.resolve(null);
    }
  }

  errorMessage(e: AxiosError | Error) {
    if ('response' in e) {
      return e.response?.data?.message || e;
    }
    return e;
  }

  async handleForm(form: ScheduledReport) {
    this.formLoading = true;
    try {
      if (this.mode === 'create') {
        await RpaOpsModule.createScheduledReport(form);
        openSuccessSnackbar.call(this, 'Report successfully created.');
      } else if (this.mode === 'edit') {
        await RpaOpsModule.editScheduledReport(form);
        openSuccessSnackbar.call(this, 'Report successfully edited.');
      }

      this.goToScheduledReportsList();
    } catch (ex: any) {
      let message = ex;
      if ('response' in ex) {
        message = ex.response?.data?.message || ex;
      }
      openErrorSnackbar.call(this, message);
      this.formLoading = false;
    }
  }
}
